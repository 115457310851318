
import { Component, Vue } from 'vue-property-decorator';
import actions from '@/shared/actions';
import getExtraUrl from '@/utils/extraUrl';

const bgWidth: number = 1479.0; // 背景图片的标准尺寸（因为是2倍图，所以除了2）
const appMarginTop: number = 550.0; // 应用区在标准尺寸下距离顶部的高度
const mainTextMarginTop: number = 200; // 标准尺寸下首标题的距离顶部高度

export interface IProject {
  addTime: number;
  approvers: string;
  icon: string;
  projectId: number;
  projectName: string;
  projectTag: string;
  showType: number;
  url?: string;
}

interface SubProjectModal {
  projectId: number;
  projectName: string;
  projectTag: string;
  projectDesc: string;
  icon: string;
  url: string;
  showType: number;
  key?: string;
  title?: string;
  path?: string;
  [propName: string]: any;
}

interface ProjectItemModal {
  categirtId: number;
  name: String;
  data: Array<SubProjectModal>;
}

interface ProjectInfo {
  roleName?: string;
  projects?: ProjectItemModal[];
  userInfo?: UserInfo;
}

interface UserInfo {
  account: string;
  email: string;
  userName: string;
  [key: string]: any;
}

@Component({})
export default class Home extends Vue {
  test: boolean = false;
  elementBg: Element | null = null;
  elementService: Element | null = null;

  projects: SubProjectModal[] = [];

  mounted() {
    window.onresize = this.dynamicLayout;
    document.getElementById('elementBg')?.addEventListener('load', this.dynamicLayout);

    actions.onGlobalStateChange((state: ProjectInfo): void => {
      if (!state.projects) {
        return;
      }
      const projects: Array<ProjectItemModal> = state!.projects || [];
      // 获取所有子应用
      this.projects = projects.reduce(
        (total: SubProjectModal[], item: ProjectItemModal) => total.concat(item.data),
        []
      );
    }, true);
  }

  // 因为首页背景图是根据不同窗口大小缩放的，为了让应用列表始终出现在背景图中的车轮下，
  // 此函数通过计算得到背景图相对于设计尺寸的缩放比例，然后把应用列表以及标题文字的marginTop
  // 进行同步缩放来实现在各个不同屏幕下的适配
  dynamicLayout() {
    const bgElement = document.getElementById('elementBg');
    const appElement = document.getElementById('elementApp');
    const mainTextWrapper = document.getElementById('mainTextDiv');
    if (bgElement && appElement && mainTextWrapper) {
      const scale = +bgElement.offsetWidth / bgWidth;
      const marginTop = appMarginTop * scale;
      appElement.style.marginTop = `${marginTop}px`;
      appElement.style.visibility = 'visible';
      mainTextWrapper.style.marginTop = `${scale * mainTextMarginTop}px`;
    }
  }

  gotoSubApp(projectTag: string) {
    const activeRule = `/main-${projectTag}`;
    if (getExtraUrl(activeRule)) {
      window.open(getExtraUrl(activeRule));
    } else {
      this.$router.push(`/${activeRule}`);
    }
  }
}
