import Home from '@/pages/home/index.vue';
import Login from '@/pages/login/index.vue';
import ErrorPage from '@/pages/ErrorPage/404.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requireAuth: false,
      title: '乾坤'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requireAuth: false,
      title: '登录'
    }
  },
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    meta: {
      requireAuth: false,
      title: '404'
    }
  }
];

export default routes;
