
import { Vue, Prop, Component } from 'vue-property-decorator';
import { classContains, getComputedStyle } from '../../../common/tools/domOpts';

interface NavConfig {
  href: string;
  text: string;
}

@Component
export default class Topbar extends Vue {
  @Prop({
    default() {
      return [];
    }
  })
  navConfig!: Array<NavConfig>;

  bottomline: any;

  mouseenterBarItem(event: any) {
    if (!classContains(event.target, 'active-item')) {
      event.target.classList.add('active-item');
      this.lineMove(event.target);
    }
  }

  mouseleaveBarItem(event: any) {
    const { classList } = event.target;
    if (classContains(event.target, 'active-item')) {
      classList.remove('active-item');
    }
  }

  mouseLeaveRightBox() {
    this.lineHidden();
  }

  lineMove(target: any) {
    // 过渡动画执行函数
    const { offsetLeft, clientWidth } = target;
    const bottomLineStyle = this.bottomline.style;

    bottomLineStyle.display = 'block';

    bottomLineStyle.left = `${
      offsetLeft + parseInt(getComputedStyle(target as any, 'padding-left')!, 10)
    }px`;

    bottomLineStyle.width = `${
      clientWidth - parseInt(getComputedStyle(target as any, 'padding-left')!, 10) * 2
    }px`;

    // 给予浏览器20ms渲染间隔时间，触发过度动画
    setTimeout(() => {
      bottomLineStyle.background = '#ffffff';
      bottomLineStyle.opacity = 1;
    }, 20);
  }

  lineHidden() {
    const display = getComputedStyle(this.bottomline, 'display');

    const bottomLineStyle = this.bottomline.style;

    // 如果bottomline目前已经是none状态，则无需污染dom内联样式
    if (display !== 'none') {
      bottomLineStyle.opacity = 0;
      setTimeout(() => {
        bottomLineStyle.display = 'none';
      }, 500);
    }
  }

  initMounted() {
    this.initUnReactiveData();
  }

  initUnReactiveData() {
    this.bottomline = this.$refs.bottomline;
  }

  mounted() {
    this.initMounted();
  }
}
