
import { Vue, Component } from 'vue-property-decorator';
import VueParticles from '@/components/vue-particles/vue-particles.vue';
import Topbar from './components/topbar.vue';

interface NavConfig {
  href: string;
  text: string;
}

@Component({
  components: {
    VueParticles,
    Topbar
  }
})
export default class Login extends Vue {
  navConfig: Array<NavConfig> = [];

  goLogin() {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const redirect = this.$route.query.redirect || '';

    window.location.href = `${baseUrl}/nr-qiankun-center/sso/passport/login?followup=${redirect}`;
  }
}
