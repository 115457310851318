/**
 * API 代理设置只做参考，你可以粘贴到同级目录 local.defines.js 文件中
 *
 * @example
 * 开发返利系统时配置 API 代理方法：
 * 1. 修改或新增 local.defines.js 中 getAPIProxy 中对应的 case
 * 2. 修改 local.defines.js 中 getAPIProxy 调用时的参数
 * 3. 重启主应用服务
 *
 * local.defines.js 在 gitignore 列表，所以本地开发结合主子应用预览时等同于不修改主项目
 *
 * @author zhaoke@xiaomi.com
 */
const localProxyConfig = getAPIProxy();

function getAPIProxy(app = 'rebate') {
  let res = {};
  switch (app) {
    case 'rebate':
      res = {
        '/api': {
          target: 'http://rebate-d.test.mi.com',
          changeOrigin: true
        }
      };
      break;
    case 'entwechat':
      res = {
        '/v1': {
          target: 'http://work-be.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            '^/api/v1': '/v1'
          }
        },
        '/api': {
          target: 'http://qyweixin.test.d.mi.com',
          changeOrigin: true
        }
      };
      break;
    case 'retailcollege':
      res = {
        '/api': {
          target: 'http://xmmionegw-outer.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            '^/api': ''
          }
        },
        '/qiankun': {
          target: 'http://work-be.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            qiankun: ''
          }
        }
      };
      break;
    case 'calculator':
      res = {
        '/mock': {
          target: 'http://mock.be.mi.com/mock/2646',
          changeOrigin: true,
          pathRewrite: {
            '^/mock': ''
          }
        },
        '/test': {
          target: 'http://sdc-d.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            '^/test': ''
          }
        }
      };
      break;
    case 'msgsystem':
      res = {
        '/v1': {
          target: 'http://work-be.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            '^/api/v1': '/v1'
          }
        },
        '/api': {
          target: 'http://newretail-message.test.mi.com',
          changeOrigin: true,
          pathRewrite: {
            '^/api': '/admin'
          }
        }
      };
      break;

    default:
      break;
  }

  return res;
}

/**
 * 本地开发，如果需要在主应用中预览子应用，则 app.ts 中需要提供启动的子应用端口以生成正确的 entry
 * 为了避免每次调试不同子应用都修改主应用代码，我们把依赖子应用的内容抽离
 * 这样，当调试不同子应用
 *
 * @example
 * 在主应用中调试返利系统时：
 * 1. 在 local.defines.js 中 localPorts 字段，添加 `rebate: 10200`
 *
 * @author zhaoke@xiaomi.com
 */
const localPorts = {
  // 返利系统
  rebate: 10200,
  'car-delivery': 10202,
  _default: 9999
};

module.exports = {
  localProxyConfig,
  localPorts
};
