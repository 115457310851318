
import { Component, Vue, Watch } from 'vue-property-decorator';
import Topbar from '@/components/topbar/index.vue';
import actions from '@/shared/actions';

@Component({
  components: {
    Topbar
  }
})
export default class App extends Vue {
  private showTopbar: boolean = false;

  private isHome: boolean = false;
  private watermarkText: string = '';

  mounted() {
    actions.onGlobalStateChange((state) => {
      if (state.userInfo) {
        this.watermarkText = `${state.userInfo.userName}  ${state.userInfo.mobile.slice(-4)}`;
      }
    }, true);
  }
  
  @Watch('$route', { immediate: true, deep: true })
  onRouteNameChange(newVal: any /** Route */) {
    this.showTopbar = newVal.path !== '/login';
    this.isHome = newVal.path === '/';
  }
}
