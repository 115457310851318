var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"nav_block"},[_c('div',{staticClass:"nav_left"},[_c('img',{attrs:{"src":"https://cdn.cnbj1.fds.api.mi-img.com/ecy/ecy-icon/miBrand-logo.svg"}}),_c('span',{staticClass:"system_name",on:{"click":_vm.showApps}},[_vm._v(_vm._s(_vm.curProject.name))]),_c('img',{staticClass:"icon-toggle",attrs:{"src":"https://cdn.cnbj3-fusion.fds.api.mi-img.com/ecy-icon-svg/svg/symbol-fill-chevron-down.svg"},on:{"click":_vm.showApps}})]),(_vm.showRouteMenu)?_c('div',{staticClass:"nav_center"},_vm._l((_vm.routeList),function(rule,i){return _c('div',{key:rule.path,staticClass:"nav_item"},[(rule.children && rule.children.length > 0)?[_c('el-popover',{ref:"popover",refInFor:true,attrs:{"placement":"bottom-start","popper-class":"popover_nav_list","width":"180","trigger":"click"},on:{"show":function($event){return _vm.changeArrow(i)},"hide":function($event){return _vm.changeArrow(i)}}},[_c('div',{staticClass:"select_nav_list"},_vm._l((rule.children),function(child,index){return _c('p',{key:index,class:{ active: child.resourceUrl == _vm.activeSubMenuPath },on:{"click":function($event){return _vm.changePath(child.resourceUrl, i)}}},[_vm._v(" "+_vm._s(child.resourceName)+" "),(child.resourceUrl == _vm.activeSubMenuPath)?_c('i',{staticClass:"el-icon-check",attrs:{"size":"16px","color":"#3d7eff"}}):_vm._e()])}),0),_c('span',{class:{
              menu: true,
              active: _vm.activeMenuPath == rule.resourceUrl
            },attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.activeMenuPath == rule.resourceUrl ? rule.children.find((item) => item.resourceUrl === _vm.activeSubMenuPath) ?.resourceName || rule.resourceName : rule.resourceName)+" "),_c('i',{staticClass:"el-icon-caret-bottom",class:{ down: rule.arrow !== 'down' },attrs:{"size":"16px","color":"#fff"}})])])]:_c('span',{class:{
          menu: true,
          active: _vm.activeMenuPath == rule.resourceUrl
        },on:{"click":function($event){return _vm.changePath(rule.resourceUrl, i)}}},[_vm._v(" "+_vm._s(rule.resourceName)+" ")])],2)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }