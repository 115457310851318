/* istanbul ignore next */

import Vue from 'vue';

const isServer = Vue.prototype.$isServer;

/* istanbul ignore next */
export const on = (function () {
  if (!isServer && document.addEventListener) {
    return function (element: any, event: any, handler: Function) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  }
  return function (element: any, event: any, handler: Function) {
    if (element && event && handler) {
      element.attachEvent(`on${event}`, handler);
    }
  };
})();

/* istanbul ignore next */
export const off = (function () {
  if (!isServer && document.removeEventListener) {
    return function (element: any, event: any, handler: Function) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  }
  return function (element: any, event: any, handler: Function) {
    if (element && event) {
      element.detachEvent(`on${event}`, handler);
    }
  };
})();
