import store from './store';

class Shared {
  /**
   * 获取 Token
   */
  public getToken(): string {
    const state = store.getState();
    return state.token || '';
  }

  /**
   * 设置 Token
   */
  public setToken(token: string): void {
    // 将 token 的值记录在 store 中
    store.dispatch({
      type: 'SET_TOKEN',
      payload: token
    });
  }


  public getIsShowMenus(): boolean {
    const state = store.getState();
    return state.showMenus;
  }

  // @deprecated use setMenuRouter instead
  public setMenus(payload: boolean): void {
    store.dispatch({
      type: 'SET_SHOWMENUS',
      payload
    });
  }


  public setMenuRouter(payload: any): void {
    console.log('set menu router get called: ', payload)
    store.dispatch({
      type: 'SET_MENUROUTER',
      payload
    });
  }

  public setToolboxTrigger(payload): void {
    store.dispatch({
      type: 'SET_TOOLBOX_TRIGGER',
      payload
    })
  }

  public getToolboxTrigger(): undefined | (() => void) {
    const state = store.getState();
    return state.triggerToolbox;
  }

  public getSubappRouter(): any {
    const state = store.getState();
    return state.subappRouter;
  }

  public subscribe(cb) {
    return store.subscribe(() => {
      cb(store.getState())
    })
  }
}

const shared = new Shared();
export default shared;