
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import actions from '@/shared/actions';
import shared from '@/shared';

@Component({})
export default class Setting extends Vue {
  @Prop({ default: () => {} }) userInfo: any;
  @Prop({
    default: () => ({ name: '', tag: '' })
  })
  curProject!: { name: string; tag: string };
  @Prop({ default: '' }) roleName!: string;

  triggerSubappToolbox() {
    const trigger = shared.getToolboxTrigger();
    if (trigger) {
      trigger();
    } else {
      console.error('has toobox but no trigger provided');
    }
  }

  @Emit('logout')
  logout() {}

  showUser: boolean = false;

  hasToolBox: boolean = true;
  unsubscribe: any;

  mounted() {
    this.unsubscribe = shared.subscribe((store) => {
      this.hasToolBox = !!shared.getToolboxTrigger();
    });
  }

  beforeDestroy() {
    this.unsubscribe();
    actions.offGlobalStateChange();
  }

  // 处理名字过长
  handleName(str: string) {
    if (!str) return;
    return str.length > 6 ? `${str.slice(0, 6)}...` : str;
  }
}
