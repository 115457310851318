import { createStore } from 'redux';

export type State = {
  token?: string;
  showMenus: boolean;
  triggerToolbox?: () => void;
  subappRouter: any;
};

type Action = {
  type: string;
  payload: any;
};

const reducer = (state: State = {
  showMenus: true,
  subappRouter: null,
}, action: Action): State => {
  switch (action.type) {
    default:
      return state;
    // 设置 Token
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload
      };
    // 设置是否展示子应用顶部菜单栏
    case 'SET_SHOWMENUS':
      return {
        ...state,
        showMenus: action.payload
      };
    case 'SET_TOOLBOX_TRIGGER':
      return {
        ...state,
        triggerToolbox: action.payload
      };
    case 'SET_MENUROUTER':
      return {
        ...state,
        subappRouter: action.payload,
        showMenus: true
      }
  }
};

const store = createStore<State, Action, unknown, unknown>(reducer);

export default store;
