module.exports = {
  // upc展示类型
  parentTag: 'car-work-outer',
  // 域名
  domainName: {
    test: 'v.test.mi.com',
    pre: 'v.pre.mi.com',
    production: 'v.mi.com'
  },
  // document.title
  title: '汽车工作台'
};
