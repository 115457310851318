import { get } from './http';
import { ProjectListDataModal, ProjectItemModal, SubProjectModal } from '../types/modal';
import { env } from './config';

const { parentTag } = require('../../config');
export interface IResponse<T> {
  code: number;
  msg: string;
  data: T;
}

export function apiGetUserInfo() {
    return get('/nr-qiankun-center/sso/getUserInfo');
}

export function apiGetUserProjectListByGroup() {
  return get(`/nr-qiankun-center/project/listUserCategoryProject?parentTag=${parentTag}`).then(
    (res: any): IResponse<ProjectListDataModal> => {
      if (res.code === 0) {
        (res.data || []).forEach((project: ProjectItemModal) => {
          (project.data || []).forEach((subItem: SubProjectModal) => {
            delete subItem.url;
          });
        });
      }
      return res;
    }
  );
}

export function getMenuList(path: String) {
  return get(`/nr-qiankun-center/resource/getAuthMenu`, {
    baseURL: env.upcApiBase,
    method: 'GET',
    params: {
      projectTag: path // projectTag参数值为对应项目命名
    }
  }).then(
    (res: any) => {
      if (res.code === 0) {
        return res;
      }
    }
  );
}
