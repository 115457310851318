// https://xiaomi.f.mioffice.cn/docx/doxk4DAQL8oSUsSQoexnf9OCbFd

import { RegistrableApp } from '@/micro/es/interfaces';
import { PerformanceTrack } from '@mi/performance-track';

function getSubAppName(apps: RegistrableApp[], pageUrl: string) {
  const activeSubApp = '/' + pageUrl.split('/')[1];
  const subApp: any = apps.find((app) => app.activeRule === activeSubApp);

  return subApp?.props.projectName;
}

export let perfTrack: PerformanceTrack | null = null;
export const performanceTrackInit = (apps: RegistrableApp[]) => {
  try {
    perfTrack = new PerformanceTrack({
      group: 'BJ-FE', // 非必填，建议上报
      system: '汽车工作台外网', // 非必填，建议上报
      env: process.env.VUE_APP_ENV, // 非必填，建议上报
      excludeRequests: [/\.(js|css|html)$/], // 非必填，过滤符合条件的网络请求
      distinctSearch: true, // 非必填，search参数代表不同页面时该值设为true
      container: ['#main-app', '#frame'],
      behaviourTrack: true,
      excludeNodes: ['div#__ecyWatermask']
    },
    (result, status) => {
      // manualTrack为true时处理数据后调用trackData方法手动上报
      const subAppName = getSubAppName(apps, result.pageUrl);
      result.module = subAppName;
      console.log('performance result', { result, status });

      perfTrack?.trackData(result);
    }
  );
    perfTrack.initTrack();
  } catch (error) {
    console.warn(`PerformanceTrackInit Error: ${error}`);
  }
};

export const setOnetrackUser = ({
  account,
  miID,
  userName,
  email
}: {
  userName: string;
  account: string;
  email: string;
  miID: string;
}) => {
  // @ts-ignore
  window?.onetrack?.('performance.set', {
    // 中文用户名
    username: userName,
    // 内网account为邮箱前缀，外网账号account一般为mid
    userId: account || miID
  });
};